import firebase from "firebase/compat/app";
import getAuth from "firebase/compat/auth";
const {
  initializeAppCheck,
  ReCaptchaV3Provider,
} = require("firebase/app-check");

declare global {
  // var must be used for global scopes
  // https://www.typescriptlang.org/docs/handbook/release-notes/typescript-3-4.html#type-checking-for-globalthis
  // eslint-disable-next-line no-var
  var FIREBASE_APPCHECK_DEBUG_TOKEN: boolean | string | undefined;
}

const firebaseConfig = {
  apiKey: "AIzaSyDxDkvRqxHDnHW0v0UlrVWNeLqMPmaq8rw",

  authDomain: "rumpelutility-857c1.firebaseapp.com",

  databaseURL:
    "https://rumpelutility-857c1-default-rtdb.europe-west1.firebasedatabase.app",

  projectId: "rumpelutility-857c1",

  storageBucket: "rumpelutility-857c1.appspot.com",

  messagingSenderId: "842976140184",

  appId: "1:842976140184:web:4a2c3e6daf722fed2f69ed",
};

const app = firebase.initializeApp(firebaseConfig);
//self.FIREBASE_APPCHECK_DEBUG_TOKEN = true;

const appCheck = initializeAppCheck(app, {
  provider: new ReCaptchaV3Provider(process.env.REACT_APP_SECRET_TOKEN),
});

export default firebase;
