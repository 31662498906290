import styled from "styled-components";
import { Heading } from "rebass";
// @ts-ignore
import { LteTablet } from "../utils/MediaQueries";

const HeadingWrapper = styled(Heading)`
  font-family: ${(props) => props.theme.fonts.heading};
  margin-bottom: 90px;
  line-height: 1.75em;
  color: black;
  display: inline-block;
  max-width: 1500px;
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    margin: 30px 0 60px;
    line-height: 1.6em;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    margin: 20px 0 40px;
    line-height: 1.4em;
  }
`;
const DivWrapper = styled.div`
  background-image: url(./images/pigeon.png);
  background-repeat: no-repeat;
  background-position: 83% 65%;
  background-size: 7%;
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    background-image: none;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    background-image: none;
  }
`;

function HeadingCom() {
  return (
    <DivWrapper>
      <HeadingWrapper as="h1">
        RUMPEL.
        <br />A BAD-ASS{" "}
        <LteTablet>
          <br />
        </LteTablet>
        STREET ART{" "}
        <LteTablet>
          <br />
        </LteTablet>
        CHARACTER{" "}
        <LteTablet>
          <br />
        </LteTablet>
        ENTERING NFTs.
      </HeadingWrapper>
    </DivWrapper>
  );
}

export default HeadingCom;
