import React, { Dispatch, SetStateAction, useState } from "react";
import styled from "styled-components";
import { Box } from "rebass";
// @ts-ignore
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { CardActionArea } from "@mui/material";
import Modal from "@mui/material/Modal";
import firebase from "../utils/Firebase";
import "firebase/compat/database";
import Claimed from "../img/claimed.png";
import { GteDesktop, GteTablet, LteTablet } from "../utils/MediaQueries";

const DivForReward = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  min-width: 280px;
  padding: 70px;
  @media screen and (${(props) => props.theme.breakpoints.lteTabletQuery}) {
    padding: 0px;
    width: 240px;
  }
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 2800px;
`;

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  minHeight: 550,
  minWidth: 250,
  maxWidth: 800,
  maxHeight: 800,
  backgroundColor: "white",
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  border: "2px solid #000",
  boxShadow: "5px 5px",
  p: 4,
  backgroundImage: "url(./images/CardBack.png)",
  backgroundRepeat: "no-repeat",
  backgroundPosition: "left top",
  backgroundSize: "12%",
  textAlign: "center",
};

const ImageStyle = styled.img`
  position: absolute;
  right: 0;
  left: 0;
  top: 20px;
  margin: 0 auto !important;
  height: 200px;
  width: 400px;
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    left: 0;
    right: 0;
    margin: 0 auto !important;
    width: 1OO%;
  }
  @media screen and (${(props) => props.theme.breakpoints.lteTabletQuery}) {
    left: 0;
    right: 0;
    top: 10px;
    margin: 0 auto !important;
    height: 150px;
    width: 100%;
  }
`;

interface ObjCards {
  rewardList: any[];
  setEthereumAccount: Dispatch<SetStateAction<string | null>>;
  eligibilityArray: boolean[];
  ethereumAccount: string | null;
  setAlertVisibility: Dispatch<SetStateAction<boolean>>;
}

const CardCom = ({
  rewardList,
  setEthereumAccount,
  eligibilityArray,
  ethereumAccount,
  setAlertVisibility,
}: ObjCards) => {
  const [rewardCoupon, setRewardCoupon] = React.useState();
  const handleOpen = (_rewardList: any) => async () => {
    setSelectedCard((selectedCard: { id: any }) =>
      selectedCard.id === _rewardList.id ? null : _rewardList
    );
    const GetRewards = firebase
      .database()
      .ref("Rewards")
      .child(_rewardList.id.toString());

    var snapshop = await GetRewards.once("value");
    setRewardCoupon(snapshop.val().coupon);
  };
  const handleClose = () => {
    setSelectedCard([]);
  };
  const [selectedCard, setSelectedCard] = useState<any>([]);

  async function connectMetamaskWallet(): Promise<void> {
    //to get around type checking
    (window as any).ethereum
      .request({
        method: "eth_requestAccounts",
      })
      .then(async (accounts: string[]) => {
        setEthereumAccount(accounts[0]);
      })
      .catch((error: any) => {
        alert(`Something went wrong: ${error}`);
      });
  }

  function allertShowFun() {
    setAlertVisibility(true);
    window.scrollTo(0, 0);
  }
  function UnsafeComponent({ html }: any) {
    return <div dangerouslySetInnerHTML={{ __html: html }} />;
  }

  return (
    <div style={{ display: "flex", flexDirection: "column" }}>
      <CardsWrapper>
        {rewardList.map((rew, index) => (
          <>
            <LteTablet>
              <Card
                key={rew.id}
                sx={
                  rew.Claimed
                    ? {
                        width: 300,
                        height: 650,
                        margin: 5,
                        opacity: 0.5,
                        boxShadow: "5px 5px",
                        border: "1px solid",
                      }
                    : eligibilityArray[index] != true
                    ? {
                        width: 300,
                        height: 650,
                        margin: 5,
                        opacity: 1,
                        boxShadow: "5px 5px",
                        border: "1px solid",
                      }
                    : {
                        width: 300,
                        height: 550,
                        margin: 5,
                        opacity: 0.5,
                        boxShadow: "5px 5px",
                        border: "1px solid",
                      }
                }
              >
                <CardActionArea
                  onClick={
                    ethereumAccount === null
                      ? connectMetamaskWallet
                      : eligibilityArray.length <= 0
                      ? () => console.log("Not loaded yet")
                      : eligibilityArray[index] != true
                      ? handleOpen(rew)
                      : allertShowFun
                  }
                  sx={{ height: "100%" }}
                >
                  <CardMedia
                    component="img"
                    width="250"
                    image={rew.img}
                    alt="green iguana"
                    style={{ border: "1px solid" }}
                    sx={{ objectFit: "contain", position: "absolute", top: 0 }}
                  />
                  {eligibilityArray[index] != false && rew.Claimed ? (
                    <ImageStyle src={Claimed}></ImageStyle>
                  ) : (
                    <></>
                  )}
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{
                        position: "absolute",
                        top: "25%",
                        left: 0,
                        right: 0,
                        padding: 3,
                        paddingTop: 5,
                        fontSize: 20,
                      }}
                    >
                      <UnsafeComponent html={rew.name} />
                    </Typography>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{
                        position: "absolute",
                        top: "55%",
                        left: 0,
                        right: 0,
                        paddingLeft: 2,
                        paddingRight: 2,
                      }}
                    >
                      <UnsafeComponent html={rew.description} />
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{
                        color: "#bd091f",
                        textDecoration: "underline",
                        paddingTop: 2,
                        fontWeight: "bold",
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        padding: 5,
                      }}
                    >
                      Claim Now
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <Modal
                  open={selectedCard.id === rew.id}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <DivForReward>
                      <UnsafeComponent html={rew.RewardTitle} />
                      <div style={{ overflowWrap: "break-word" }}>
                        <UnsafeComponent html={rew.RewardDescription} />
                      </div>
                      <UnsafeComponent html={rewardCoupon} />
                    </DivForReward>
                  </Box>
                </Modal>
              </Card>
            </LteTablet>
            <GteDesktop>
              <Card
                key={rew.id}
                sx={
                  rew.Claimed
                    ? {
                        width: 450,
                        height: 600,
                        margin: 5,
                        opacity: 0.5,
                        boxShadow: "5px 5px",
                        border: "1px solid",
                      }
                    : eligibilityArray[index] != true
                    ? {
                        width: 450,
                        height: 600,
                        margin: 5,
                        opacity: 1,
                        boxShadow: "5px 5px",
                        border: "1px solid",
                      }
                    : {
                        width: 450,
                        height: 600,
                        margin: 5,
                        opacity: 0.5,
                        boxShadow: "5px 5px",
                        border: "1px solid",
                      }
                }
              >
                <CardActionArea
                  onClick={
                    ethereumAccount === null
                      ? connectMetamaskWallet
                      : eligibilityArray.length <= 0
                      ? () => console.log("Not loaded yet")
                      : eligibilityArray[index] != true
                      ? handleOpen(rew)
                      : allertShowFun
                  }
                  sx={{ height: "100%" }}
                >
                  <CardMedia
                    component="img"
                    height="250"
                    width="250"
                    image={rew.img}
                    alt="green iguana"
                    style={{ border: "1px solid" }}
                    sx={{ objectFit: "fill", position: "absolute", top: 0 }}
                  />
                  {eligibilityArray[index] != false && rew.Claimed ? (
                    <ImageStyle src={Claimed}></ImageStyle>
                  ) : (
                    <></>
                  )}
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: 0,
                        right: 0,
                        paddingLeft: 2,
                        paddingRight: 2,
                      }}
                    >
                      <UnsafeComponent html={rew.name} />
                    </Typography>
                    <Typography
                      variant="body1"
                      color="text.primary"
                      sx={{
                        position: "absolute",
                        top: "65%",
                        left: 0,
                        right: 0,
                        padding: 1,
                      }}
                    >
                      <UnsafeComponent html={rew.description} />
                    </Typography>
                    <Typography
                      gutterBottom
                      variant="h6"
                      component="div"
                      sx={{
                        color: "#bd091f",
                        textDecoration: "underline",
                        paddingTop: 2,
                        fontWeight: "bold",
                        position: "absolute",
                        bottom: 0,
                        left: 0,
                        right: 0,
                        padding: 5,
                      }}
                    >
                      Claim Now
                    </Typography>
                  </CardContent>
                </CardActionArea>
                <Modal
                  open={selectedCard.id === rew.id}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box sx={style}>
                    <DivForReward>
                      <UnsafeComponent html={rew.RewardTitle} />
                      <UnsafeComponent html={rew.RewardDescription} />
                      <UnsafeComponent html={rewardCoupon} />
                    </DivForReward>
                  </Box>
                </Modal>
              </Card>
            </GteDesktop>
          </>
        ))}
      </CardsWrapper>
    </div>
  );
};

export default CardCom;
