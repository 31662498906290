import styled from "styled-components";
import { Flex, Box, Link, Image, Button } from "rebass";
import SocialLinks from "./SocialLinks";
import { GteDesktop } from "../utils/MediaQueries";
import { backgroundTransitions } from "react-hover-css";
import { Dispatch, SetStateAction } from "react";

const Topbar = styled(Flex)`
  display: flex;
  z-index: 2;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  max-width: ${(props) => props.theme.layouts.xl};
  height: 120px;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-left: 250px !important;
  padding-right: 250px !important;
  background-color: transparent;
  color: ${(props) => props.theme.colorsInvert.text};
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    height: 100px;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    height: 80px;
    padding-left: 30px !important;
    padding-right: 30px !important;
  }
`;
const Logo = styled(Image)`
  width: 225px;
  height: 87px;
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    width: 187px;
    height: 72px;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    width: 150px;
    height: 58px;
  }
`;
const Menu = styled(Box)`
  display: none;
  @media screen and (${(props) => props.theme.breakpoints.gteDesktopQuery}) {
    display: block;
  }
`;

const ButtonBox = styled(Box)`
  display: flex;
  justify-content: center;
  padding-top: 10px;
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    height: 10px; !important
    display: block;
    justify-content: right;
  }
`;

const MenuItem = styled(Link)`
  margin-left: 20px !important;
  margin-right: 20px !important;
  text-transform: uppercase;
  text-decoration: none;
  font-size: ${(props) => props.theme.fontSizeElements.body2}px;
  color: ${(props) => props.theme.colorsInvert.text};
  transition: ${(props) => props.theme.animation.transitionBasic};
  &:hover {
    color: ${(props) => props.theme.colorsInvert.primary};
  }
`;

const CtaButton = styled(Button)`
  ${backgroundTransitions.hvrSweepToRight}
  z-index: 2;
  position: absolute;
  padding: 15px 30px !important;
  font-weight: bold;
  font-family: ${(props) => props.theme.fonts.body};
  font-size: ${(props) => props.theme.fontSizeElements.body2}px !important;
  border-radius: 3px !important;
  background-color: ${(props) => props.theme.colors.primary} !important;
  cursor: pointer;
  &:before {
    background-color: ${(props) => props.theme.colors.primaryActive} !important;
  }
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
    top: 390px;
    left: 0;
    right: 0;
    margin: 0 auto !important;
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    top: 330px;
    left: 0;
    right: 0;
    margin: 0 auto !important;
    font-size: 15px !important;
    padding: 15px 30px !important;
  }
`;

const style = {
  position: "absolute" as "absolute",
};

interface NavbarInter {
  setEthereumAccount: Dispatch<SetStateAction<string | null>>;
  ethereumAccount: string | null;
  isMetamaskInstalled: boolean;
}

const Navbar = ({
  setEthereumAccount,
  ethereumAccount,
  isMetamaskInstalled,
}: NavbarInter) => {
  async function connectMetamaskWallet(): Promise<void> {
    //to get around type checking
    (window as any).ethereum
      .request({
        method: "eth_requestAccounts",
      })
      .then(async (accounts: string[]) => {
        setEthereumAccount(accounts[0]);
      })
      .catch((error: any) => {
        alert(`Something went wrong: ${error}`);
      });
  }

  return (
    <>
      <Topbar px={2} alignItems="center">
        <Link href="/">
          <Logo src="./images/logo.png" alt="Rumpel" />
        </Link>
        <Menu mx="auto"></Menu>
        <div>
          <GteDesktop>
            <>
              <SocialLinks
                iconSize="lg"
                instagram="https://www.instagram.com/rumpel.crew/"
                twitter="https://twitter.com/rumpelcrew"
                opensea="https://opensea.io/collection/rumpelcrew"
                color="dark"
              />
            </>
          </GteDesktop>
          {isMetamaskInstalled ? (
            <ButtonBox>
              <CtaButton variant="primary" onClick={connectMetamaskWallet}>
                {ethereumAccount != null ? "Connected" : "Log-in"}
              </CtaButton>
            </ButtonBox>
          ) : (
            <></>
          )}
        </div>
      </Topbar>
    </>
  );
};

export default Navbar;
