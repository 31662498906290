import React, { Dispatch, SetStateAction, useEffect, useState } from "react";
import "firebase/compat/database";
import firebase from "../utils/Firebase";
import CardCom from "./CardCom";
import styled from "styled-components";
import PaginationItem from "./PagginationItem";
import { ethers, providers } from "ethers";
import Alert from "@mui/material/Alert";
import Fade from "@mui/material/Fade";
import { Box, Button } from "rebass";
import { backgroundTransitions } from "react-hover-css";
const StyledDiv = styled.div`
  display: flex !important;
  justify-content: center;
`;

const CtaButton = styled(Button)`
  ${backgroundTransitions.hvrSweepToRight}
  z-index: 2;
  position: relative;
  top: -110px;
  padding: 15px 30px !important;
  font-weight: bold;
  font-family: ${(props) => props.theme.fonts.body};
  font-size: ${(props) => props.theme.fontSizeElements.body2}px !important;
  border-radius: 3px !important;
  background-color: ${(props) => props.theme.colors.primary} !important;
  cursor: pointer;
  &:before {
    background-color: ${(props) => props.theme.colors.primaryActive};
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
    top: -240px;
  }
`;

interface Body {
  setEthereumAccount: Dispatch<SetStateAction<string | null>>;
  ethereumAccount: string | null;
  isMetamaskInstalled: boolean;
}

const Body = ({
  setEthereumAccount,
  ethereumAccount,
  isMetamaskInstalled,
}: Body) => {
  const [rewardList, setRewardList] = useState<any[]>([]);
  const [loading, setLoading] = useState(false);
  const [eligibilityArray, setEligibilityArray] = useState<boolean[]>([]);
  const [activePage, setActivePage] = useState(1);
  const [cardsPerPage, setCardsPerPage] = useState(20);
  const [infoAboutTokens, setInfoAboutTokens] = useState<any[]>([]);
  const [alertVisibility, setAlertVisibility] = useState(false);

  useEffect(() => {
    GetTokenInfo();
  }, [infoAboutTokens]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      const reward = firebase.database().ref("Rewards");
      reward.on("value", (snapshop) => {
        const rewards = snapshop.val();
        const rewardList = [];
        for (let id in rewards) {
          delete rewards[id].coupon;
          rewardList.push(rewards[id]);
        }
        setRewardList(rewardList);
        setLoading(false);
      });
    };
    fetchData();
  }, []);

  async function getUserTokens(): Promise<void> {
    initializeProvider();

    const TraitsOfUsersTokens: Array<object> = [];
    const contractRumpels = new ethers.Contract(
      "0x956617Fc1e05a4cfc8C7911dFaa0A2C188F9Aa03",
      [
        "function balanceOf(address owner) view returns (uint256)",
        "function tokenOfOwnerByIndex(address owner, uint256 index) view returns (uint256)",
      ],
      provider
    );
    if (ethereumAccount != null) {
      const signer = provider.getSigner()  
      const signedMessage = await signer.signMessage("Rumpel utility")
      const signerAddr = await ethers.utils.verifyMessage("Rumpel utility", signedMessage)
      if (signerAddr.toLowerCase() !== ethereumAccount) {
        const arrayhelp = []
        for (let i = 0; i < rewardList.length; i++) {
          arrayhelp.push(true)
        }
        setEligibilityArray(arrayhelp)
        return
      }
      const number_of_tokens = await contractRumpels.balanceOf(
        ethereumAccount
      ); // ethereumAccount
      const owned_tokens = parseInt(number_of_tokens._hex, 16); // Hex value to dec!
      if (owned_tokens != 0) {
        const TokensOfUsers = [];
        for (let i = 0; i < owned_tokens; i++) {
          const tokenNumber = await contractRumpels.tokenOfOwnerByIndex(
            ethereumAccount,
            i
          );
          TokensOfUsers.push(parseInt(tokenNumber._hex, 16));
        }

        const TraitsOfUsersTokens: any[] = [];

        for (var i = 0; i < TokensOfUsers.length; i++) {
          try {
            const GetTraits = firebase
              .database()
              .ref("Rumpels")
              .child(TokensOfUsers[i].toString());

            var snapshop = await GetTraits.once("value");

            if (snapshop.exists()) {
              const result = snapshop.val();
              TraitsOfUsersTokens.push(result);
            }
          } catch (error) {}
        }
        setInfoAboutTokens(TraitsOfUsersTokens);
      } else {
        setInfoAboutTokens(TraitsOfUsersTokens);
      }
    }
  }

  useEffect(() => {
    getUserTokens();
  }, [ethereumAccount]);

  let provider: any;
  const initializeProvider = () => {
    if ((window as any).ethereum !== undefined) {
      provider = new ethers.providers.Web3Provider((window as any).ethereum);
    } else {
      provider = new ethers.providers.JsonRpcProvider(
        process.env.REACT_APP_RPC_URL
      );
    }
  };

  function GetTokenInfo() {
    const arraytest: boolean[] = [];
    rewardList.map((rew, index) => {
      let value = true;
      const keys = Object.keys(rew.elighibility);
      infoAboutTokens.map((token) => {
        keys.forEach((key) => {
          try {
            const elighi =
              rew.elighibility[key as keyof typeof rew.elighibility]?.split(
                ","
              );
            if (elighi?.includes(String(token[key])) && rew.Claimed === false) {
              value = false;
            } else {
            }
          } catch {
            console.log(rew.elighibility);
          }
        });
      });
      let eligible = {
        eligible: value,
      };
      Object.assign(rew, eligible);
      arraytest.push(value);
    });
    rewardList.sort(function (a, b) {
      return a.eligible - b.eligible;
    });
    arraytest.sort((a, b) => Number(a) - Number(b));
    setEligibilityArray(arraytest);
  }

  const indexOfLastCard = activePage * cardsPerPage;
  const indexOfFirstCard = indexOfLastCard - cardsPerPage;
  const currentCard = rewardList.slice(indexOfFirstCard, indexOfLastCard);

  const paginate = (pageNumber: React.SetStateAction<number>) => {
    setActivePage(pageNumber);
  };

  return (
    <>
      {!isMetamaskInstalled ? (
        <h1 style={{ backgroundColor: "#bd091f", color: "white" }}>
          If you want to interact with the app please install metamask extension
          or install mobile app!
        </h1>
      ) : (
        <></>
      )}
      <Fade
        in={alertVisibility}
        timeout={{ enter: 1000, exit: 3000 }}
        addEndListener={() => {
          setTimeout(() => {
            setAlertVisibility(false);
          }, 2000);
        }}
      >
        <Alert
          icon={false}
          severity="error"
          variant="filled"
          className="alert"
          sx={{
            textAlign: "centre",
            display: "block",
            position: "absolute",
            top: 0,
            width: "100%",
            padding: "0px !important",
          }}
        >
          <h3>Not so fast! You cant claim this</h3>
        </Alert>
      </Fade>
      <StyledDiv>
        {!loading ? (
          <CardCom
            ethereumAccount={ethereumAccount}
            rewardList={currentCard}
            setEthereumAccount={setEthereumAccount}
            eligibilityArray={eligibilityArray.slice(
              indexOfFirstCard,
              indexOfLastCard
            )}
            setAlertVisibility={setAlertVisibility}
          />
        ) : (
          <h2>Loading ..</h2>
        )}
      </StyledDiv>
      <PaginationItem
        cardsPerPage={cardsPerPage}
        totalCards={rewardList.length}
        paginate={paginate}
        actualpage={activePage}
      />
    </>
  );
};

export default Body;
