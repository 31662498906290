import { useState } from 'react';
import { ThemeProvider } from 'styled-components';
import theme from '../theme/theme';
import '../styles/style.css';
import Footer from './Footer';
import { LteTablet } from '../utils/MediaQueries';
import Navbar from './Navbar';
import HeadingCom from './HeadingCom';
import { useEffect } from 'react';
import styled from 'styled-components';
import {Button} from 'rebass';
import "firebase/compat/database";
import Body from './Body';
import FooterLogo from "../img/footerpic.png"
import { backgroundTransitions } from 'react-hover-css';
import HamburgerMenu from './HamburgerMenu';
import Fold from './Fold';

const StyledImg = styled.img`
    max-width: 100%;
    max-height: 100vh;
    margin: auto;
  @media screen and (${(props) => props.theme.breakpoints.tabletQuery}) {
  }
  @media screen and (${(props) => props.theme.breakpoints.mobileQuery}) {
  }
`;



function MainMenu() {
    const [ethereumAccount, setEthereumAccount] = useState<string | null>(null);
    const [isMetamaskInstalled, setIsMetamaskInstalled] = useState<boolean>(false);

    useEffect(() => {
        if ((window as any).ethereum) {
          //check if Metamask wallet is installed
          setIsMetamaskInstalled(true);
        }
      }, []);

    return (
        <ThemeProvider theme={theme}>
        <Fold/>
        <div id="outer-container">
            <LteTablet>
                <HamburgerMenu pageWrapId={"page-wrap"} outerContainerId={"outer-container"} />
            </LteTablet>
            <main id="page-wrap">
                <Navbar ethereumAccount={ethereumAccount} setEthereumAccount={setEthereumAccount} isMetamaskInstalled={isMetamaskInstalled}/>
                <HeadingCom/>
                <Body ethereumAccount={ethereumAccount} setEthereumAccount={setEthereumAccount} isMetamaskInstalled={isMetamaskInstalled}/>
                <StyledImg src={FooterLogo}></StyledImg>
                <Footer/>
            </main>
        </div>
        </ThemeProvider>
    );
}

export default MainMenu;